import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { SliceMaster } from '../components';
import AboutSlice from '../components/Slices/AboutSlice'
import VideoPlayer from '../components/Slices/VideoStreamSlice/VideoPlayer'
import WatchNext from '../components/WatchNext'

const Video = ({ pageContext: { trackId }, data: { page } }) => {
  const about = useMemo(() => { return page.slices.find(x => x.type === 'aboutSlice') }, [page])
  const track = useMemo(() => { return page.slices.find(x => x.type === 'videoStreamSlice').playlist.find(x => x.id === trackId) }, [page])
  const playlist = useMemo(() => { return page.slices.find(x => x.type === 'videoStreamSlice').playlist }, [page])


  return (
    <>
      <VideoPlayer track={track} autoPlay={false} cta={about.callToAction} />
      <AboutSlice slice={about} hideCta noBorder />
      <WatchNext tracks={playlist} currentTrack={track} />
    </>
  );
};

export const query = graphql`
query VideoPageQuery($id: String!) {
  page: sanityPage(id: { eq: $id }) {
    slices {
      ... on SanityAboutSlice {
        id: _key
        type: _type
        featuredText
        body: _rawBody
        callToAction: call_to_action {
          label
          href
          blank
        }
      }
      ... on SanityVideoStreamSlice {
        id: _key
        type: _type
        playlist {
          id: _key
          slug {
            current
          }
          description
          speaker
          title
          thumbnail: poster_new {
            ...ImageWithPreview
          }
          posterFixed: poster_new {
            ...Image
          }
          mobilePosterFixed: mobile_poster_new {
            ...ImageWithPreview
          }
          video {
            rawAsset: _rawAsset(resolveReferences: {maxDepth: 10})
            asset {
              playbackId
            }
          }
          videoDuration: video_duration
        }
      }
    }
  }
}
`;

export default Video;
