import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import gsap from 'gsap';
import { SanityImage } from '../../common';
import { ReactComponent as PlayIcon } from '../../../assets/images/PlayButton.svg';
import { ReactComponent as Spinner } from '../../../assets/images/Spinner.svg';
import './VideoStreamSlice.scss';
import useHlsPlayer from '../../../hooks/useHlsPlayer';
import useIsMobile from '../../../hooks/useIsMobile';
import { isClient } from '../../../utils/helpers';
import { Section, SplitWordsAnimation } from '../../common';
import { Link } from 'gatsby';

const VideoPlayer = ({ track, autoPlay = false, playsInline = true, cta, children }) => {
  const isMobile = useIsMobile();
  const { speaker, title } = track

  const playerRef = useRef(null);
  const timerRef = useRef();
  const posterRef = useRef();
  const timerProgressRef = useRef();
  const timerCurrentTimeRef = useRef();

  const [playing, setPlaying] = useState(autoPlay);
  const [videoReady, setVideoReady] = useState(false);
  const [duration, setDuration] = useState();
  const [muted, setMuted] = useState();

  const source = `https://stream.mux.com/${track.video.asset.playbackId}.m3u8`;

  const onVideoReady = useCallback(() => {
    setVideoReady(value => !value);
  }, []);

  const onToggleMuted = useCallback(() => { setMuted(value => !value) }, [])

  useHlsPlayer(playerRef, source);

  useEffect(() => {
    const onCanPlay = () => { setVideoReady(true) }
    const onTimeUpdate = (e) => {
      if (playerRef.current) {
        const percent = (playerRef.current.currentTime / playerRef.current.duration) * 100;
        updateTimerProgress(percent);
      }
    }
    const onPlay = () => { setPlaying(true); };
    const onPause = () => { };

    playerRef.current.addEventListener('canplay', onCanPlay)
    playerRef.current.addEventListener('timeupdate', onTimeUpdate);
    playerRef.current.addEventListener('play', onPlay);
    playerRef.current.addEventListener('pause', onPause);
    return () => {
      playerRef.current.removeEventListener('canplay', onCanPlay)
      playerRef.current.removeEventListener('timeupdate', onTimeUpdate);
      playerRef.current.removeEventListener('play', onPlay);
      playerRef.current.removeEventListener('pause', onPause);
    }
  }, [])

  const playCallback = useRef()
  const onTogglePlayback = useCallback(() => {
    if(playerRef.current) {
      if(!playing) {
        playCallback.current = playerRef.current.play()
      }
    }
  }, [playing])

  const updateTimerProgress = (percent) => {
    timerProgressRef.current.style.transform = `translate(-${100 - percent}%, 0)`;
    timerCurrentTimeRef.current.style.left = `${percent}%`;
  };

  useEffect(() => {
    gsap.to(posterRef.current, {
      opacity: videoReady && playing ? 0 : 1,
      duration: 0.5,
      ease: 'sine.out',
    });
  }, [videoReady && playing]);

  return (
    <>
      <section id="video-stream" className="video-stream-slice">
        {children}
        <Link className='toggle-menu-button' to='/'><span>Watch Live Stream</span></Link>
        <div className="video-wrapper">
          <button
            type="button"
            className={`toggle-play-button ${!playing ? 'show' : 'hide'}`}
            onClick={onTogglePlayback}
            aria-label="Unmute the audio for the video"
          >
            <PlayIcon />
          </button>
          {!videoReady && playing && (
            <div className="loading-spinner">
              <Spinner />
            </div>
          )}
          <div className="poster" ref={posterRef}>
            {isMobile ? (
              <SanityImage
                className="mobile-poster"
                image={track.mobilePosterFixed}
                alt={`A preview thumbnail of "${track.title}"`}
              />
            ) : (
              <SanityImage
                className="desktop-poster"
                image={track.posterFixed}
                alt={`A preview thumbnail of "${track.title}"`}
              />
            )}
          </div>
          <video
            ref={playerRef}
            src={source}
            autoPlay={autoPlay}
            playsInline={playsInline}
            muted={muted}
            controls
            onClick={onTogglePlayback}
            className={`video ${playing ? 'playing' : ''}`}
          />
          <div className={`progress-bar ${!playing ? 'hide' : 'show'}`}>
            <div className="timer" ref={timerRef}>
              <div className="timer-wrapper">
                <span className="timer-progress" ref={timerProgressRef} />
              </div>
              <span className="current-time" ref={timerCurrentTimeRef} />
            </div>
          </div>
        </div>
        <Section hasGrid className='single-video-meta'>
          <span className="single-video-meta__speaker">{speaker}</span>
          {cta && (
            <a
              className="single-video-meta__cta"
              href={cta.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {cta.label}
            </a>
          )}
          <h1 className="single-video-meta__title">{title}</h1>
        </Section>
      </section>
    </>
  );
};

export default VideoPlayer;
