import React, { useMemo } from 'react';
import PlaylistItem from './Slices/VideoStreamSlice/PlaylistMenu/PlaylistItem'
import Flickity from 'react-flickity-component';
import './WatchNext.scss';
import useCurrentTrackIndex from '../hooks/useCurrentTrackIndex';
import useOrderedTracks from '../hooks/useOrderedTracks';

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  cellAlign: 'left',
  contain: 'true',
};

const WatchNext = ({ tracks, currentTrack }) => {
  const { trackIndex, videoRanges, streamSeconds, totalPlaylistDuration } = useCurrentTrackIndex(tracks);
  const orderedTracks = useOrderedTracks(tracks, trackIndex, videoRanges, streamSeconds, totalPlaylistDuration);

  // Gets the next 4 tracks to show in the carousel
  const nextTracks = useMemo(() => {  
    var index = orderedTracks.indexOf(orderedTracks.find(x => x.id === currentTrack.id))
    var result = orderedTracks.slice(index + 1, index + 5)
    if(result.length < 4) {
      result = [
        ... orderedTracks.slice(0, 4 - result.length),
        ...result
      ]
    }
    return result
  }, [orderedTracks, currentTrack])

  return (
    <div className="slider-container watch-next">
      <Flickity
        className="slider-container__slider"
        options={flickityOptions}
        static
      >
        {nextTracks && nextTracks.map((track, index) => (
          <PlaylistItem
            key={track.id}
            isCurrentlyPlaying={false}
            isUpNext={false}
            track={track}
            show
          />
        ))}
      </Flickity>
    </div>
  );
};

export default WatchNext;
